import { getSocket } from "@/utils/webSocket";
import { getTime } from "@/api/home.js";
export default {
  created() {
    this.$store.commit("SET_SWITCH_CHECKED", this.$store.state.switchChecked);
    this.getTime();
  },
  mounted() {
    getSocket();
  },
  methods: {
    async getTime() {
      console.log(new Date().getTime(), 'star');
      const res = await getTime();
      // 获取服务器当前时间的时间戳（假设服务器时间以秒为单位）
      const serverTimestamp = new Date(res.data).getTime(); // 服务器当前时间戳
      // 获取本地当前时间的时间戳
      const localTimestamp = new Date().getTime(); // 本地当前时间戳
      // 计算时间差（以秒为单位）
      const timeDiffInSeconds = serverTimestamp - localTimestamp;
      // 将时间差转换为小时，并取整
      const timezoneOffsetHours = Math.round(timeDiffInSeconds / 3600 / 1000);
      const offsetMinutes = new Date().getTimezoneOffset();
      // 计算时区（注意：这只是一种近似方法，可能不够精确）
      let hours = Math.abs(offsetMinutes / 60);
      let suffix = offsetMinutes < 0 ? "+" : "-";
      const timeZone = Number(`${suffix}${hours}`);
      console.log(new Date().getTime(), 'end');
      localStorage.setItem("timeZone", Number(timezoneOffsetHours) + Number(timeZone));
    }
  }
};